import React, { useRef } from 'react';
// import { useFrame } from 'react-three-fiber';
import { useFrame } from '@react-three/fiber';
import { DoubleSide } from 'three';
import { useVideoTexture } from '@react-three/drei';
import { degToRad } from 'three/src/math/MathUtils';

const VideoCylinder = ({ videoSrc, radius, height, position, rotation, scale }) => {
  const meshRef = useRef();
  const texture = useVideoTexture("./assets/video/compressed.mp4")

  useFrame(() => {
    if (meshRef.current && texture) {
      meshRef.current.rotation.y += 0.0005; // Rotate the cylinder
    }
  });

  return (
    <mesh ref={meshRef} position={[0, 25, 0]} rotation={[0, degToRad(90), 0]}>
      <cylinderGeometry args={[15, 15, 25, 32, 1, true]} openEnded={true}/>
      {/* <meshBasicMaterial map={texture} toneMapped={false} color={0xFFFFFF} side={DoubleSide} /> */}
      <meshStandardMaterial map={texture} toneMapped={false} color={0xFFFFFF} side={DoubleSide} emissive={0x000000}/>

    </mesh>
  );
};

export default VideoCylinder;