import { useRef, useEffect } from "react";
import { DoubleSide, VideoTexture } from "three";
import { useVideoTexture } from '@react-three/drei';

  const shader = {
    uniforms: {
        displacementMap: { value: null },
        videoTexture: { value: null },
        amplitude: { value:10.0 }, 
        alphaTexture: { value: null}
      },
      vertexShader: `
      uniform float amplitude;
      uniform sampler2D displacementMap;
      uniform float time;
      varying vec2 vUv; // Add this line to define vUv
  
      // void main() {
      //   vec3 newPosition = position;
      //   vUv = uv; // Pass uv to vUv
      //   vec4 displacement = texture2D(displacementMap, uv);
      //   newPosition.z += displacement.r * amplitude;
      //   gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
      // }

      void main() {
        vec3 newPosition = position;
        vUv = uv;
        
        // Sample the displacement map
        vec4 displacement = texture2D(displacementMap, uv);
        
        // Apply smoothing using smoothstep
        float smoothFactor = smoothstep(1.0, 0.1, displacement.r);
        
        // Apply the displacement with smoothing
        newPosition.z += displacement.r * amplitude * smoothFactor;
        
        gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
      }
    `,
    fragmentShader: `
      // varying vec2 vUv; // Add this line to define vUv
      // uniform sampler2D imageTexture;
  
      // void main() {
      //   vec4 texColor = texture2D(imageTexture, vUv);
      //   gl_FragColor = texColor;
      // }

      uniform sampler2D videoTexture;
      uniform sampler2D alphaTexture;
      uniform float time;
      uniform float distortionStrength;
  
      varying vec2 vUv;
  
      void main() {
        
        // Get the alpha value from the alpha texture
        float alpha = texture2D(alphaTexture, vUv).r;
  
        // Offset the UV coordinates using the alpha value to create the distortion effect
        vec2 distortedUV = vUv + vec2(sin(alpha * time * 10.0), cos(alpha * time * 5.0)) * distortionStrength;
  
        // Get the color value from the color texture using the distorted UV coordinates
        vec4 color = texture2D(videoTexture, distortedUV);
  
        // Set the output color, using alpha for transparency
        gl_FragColor = vec4(color.rgb, alpha * color.a);
      }
    `,
  };

const VideoPlane = () => {
  const materialRef = useRef();

  const texture = useVideoTexture("./assets/video/compressed.mp4")
  useEffect(() => {
      // const vidTex = document.createElement("video");
      // vidTex.src = "./assets/video/compressed.mp4";
      // vidTex.loop = true;
      // vidTex.muted = true;
      // vidTex.crossOrigin = "Anonymous";
      // vidTex.play();

      // const videoTexture = new VideoTexture(vidTex);
      // materialRef.current.uniforms.displacementMap.value = videoTexture;
      // materialRef.current.uniforms.videoTexture.value = videoTexture;
      // materialRef.current.uniforms.alphaTexture.value = videoTexture;

      materialRef.current.uniforms.displacementMap.value = texture;
      materialRef.current.uniforms.videoTexture.value = texture;
      materialRef.current.uniforms.alphaTexture.value = texture;
    
  }, [texture]);



  return (
    <mesh position={[0, 5, 0]}>
      <planeGeometry args={[9, 16, 1000, 1000]} />
      <shaderMaterial
      ref={materialRef}
        args={[shader]}
        side={DoubleSide}
        transparent={true}
      />
    </mesh>
  );
};

export default VideoPlane;