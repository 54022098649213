import Smoke from "./Smoke";

const SmokeWrapper = () => {
  const componentsArray = new Array(1000).fill(null);
  return (
    <>
      {componentsArray.map((_, index) => (
        <Smoke key={index} />
      ))}
    </>
  );
};

export default SmokeWrapper;
