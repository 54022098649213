import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { useState } from "react";
import { TextureLoader } from "three";

const Smoke = () => {

    const smoke = useLoader(TextureLoader, "./assets/textures/smoke_01.png");

    const [position ] = useState([
        (Math.random() * 200 - 100),
        0,
        (Math.random() * 200 - 100)
    ])

    const [rotation ] = useState( Math.random() * Math.PI * 2);
    // const {camera} = useThree();
    const particleRef = useThree()
    // useFrame((state, clock)=>{

        
    //     // if(particleRef.current){
    //         // const z = particleRef.current.rotation.z;
    //         particleRef.current.lookAt( camera.position );
    //         // console.log(camera.position);
    //         // particleRef.current.rotation.z = z + clock * 0.01
    //     // }
                
    // })

    return(
        <mesh 
            position={position}
            rotation={[
                0,
                0,
                rotation
            ]}
            ref={particleRef}
        >
            <meshLambertMaterial
            color={0xFFFFFF}
            depthWrite={false}
            map={smoke}
            // color={0xFF0000}
            transparent={true} />
            <planeGeometry args={[5, 5]} />
        </mesh>
    )

}

export default Smoke;

// function update() { 
//     const dt = clock.getDelta();
//     if (particles){
//       particles.forEach( particle => {
//         const z = particle.rotation.z;
//         particle.lookAt( camera.position );
//         particle.rotation.z = z + dt * 0.1;
//       });
//     }
//     renderer.render( scene, camera );
// }

// const tex = new THREE.TextureLoader()
// .setPath(assetPath)
// .load('smoke_01.png');
// const material = new THREE.MeshLambertMaterial({
// color: 0xffffff, 
// depthWrite: false,
// map: tex, 
// transparent: true
// });
// const geometry = new THREE.PlaneGeometry(5,5);
// particles = [];

// const size = 5;

// for (let i=0; i<40; i++) {
//   const particle = new THREE.Mesh(geometry, material);
//   particle.position.set(
//     ( Math.random()-0.5) * size, 
//     ( Math.random()-0.5) * size, 
//     ( Math.random()-0.5) * size);
//   particle.rotation.z = Math.random() * Math.PI * 2;
//   scene.add(particle);
//   particles.push(particle);
// }