import Altar from "./Altar";
import Floor from "./Floor";
import Fountain from "./Fountain";
import VideoCylinder from "./VideoCylinder";
import VideoPlane from "./VideoPlane";
import Smoke from "./Smoke";
import SmokeWrapper from "./SmokeWrapper";
const Scenery = () => {
  return (
    <>
      <Floor />
      <VideoCylinder />
      <VideoPlane />
      <SmokeWrapper />

    </>
  );
};

export default Scenery;
