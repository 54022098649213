import { Vector3 } from 'three'
import { useEffect, useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { SpotLight } from '@react-three/drei'

function MovingSpot({ vec = new Vector3(), ...props }) {
    const light = useRef()
        useFrame((state, clock) => {
      light.current.target.position.lerp(vec.set(props.position[0], 0, props.position[2]), 0.1)
      light.current.target.updateMatrixWorld()

    })
    return <SpotLight castShadow ref={light} penumbra={1} distance={35} angle={Math.PI/2} attenuation={50} anglePower={4}  {...props} />
  }

  export default MovingSpot;