import { useDepthBuffer } from '@react-three/drei';
import MovingSpot from './MovingSpot';
const Lighting = () => {


    const depthBuffer = useDepthBuffer({ frames: 10 })


  return (
    <>
        <ambientLight intensity={0.2} />
         <MovingSpot depthBuffer={depthBuffer} color="#FFFFFF" position={[0, 30, 0]} intensity={20} />

         {/* <MovingSpot depthBuffer={depthBuffer} color="#FFFFFF" position={[-25, 30, 30]} intensity={0.5} />
         <MovingSpot depthBuffer={depthBuffer} color="#FFFFFF" position={[-37, 30, -13]} intensity={0.5} />
         <MovingSpot depthBuffer={depthBuffer} color="#FFFFFF" position={[-0, 30, -40]} intensity={0.5} />
         <MovingSpot depthBuffer={depthBuffer} color="#FFFFFF" position={[37, 30, -13]} intensity={0.5} />
         <MovingSpot depthBuffer={depthBuffer} color="#FFFFFF" position={[25, 30, 30]} intensity={0.5} /> */}

    </>
  )
}

export default Lighting