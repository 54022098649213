import { useThree} from "@react-three/fiber";
import { useLayoutEffect, useRef } from "react";
import { PerspectiveCamera } from "@react-three/drei";

function CameraRig(props) {
  const cameraRef = useRef();
  const set = useThree(({ set }) => set);
  const size = useThree(({ size }) => size);

  useLayoutEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.aspect = size.width / size.height;
      cameraRef.current.updateProjectionMatrix();
    }
  }, [size, props]);

  useLayoutEffect(() => {
    set({ camera: cameraRef.current });
  }, [set]);
  return <perspectiveCamera ref={cameraRef} position={[0, 2.5, 100]} />;
}

export default CameraRig;
