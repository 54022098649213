import { useLoader } from "@react-three/fiber";
import {
  TextureLoader,
  EquirectangularReflectionMapping,
} from "three";

const Chrome = (props) => {
  const { emissive } = props;
  const envMap = useLoader(TextureLoader, "./assets/textures/hdricopy.png");
  envMap.mapping = EquirectangularReflectionMapping;

  return (
    <meshPhongMaterial
      color={emissive?(0xFFFFFF):(0xDDDDDD)}
      envMap={envMap}
      reflectivity={1}
      shininess={100}
      specular={0xFFFFFF}
    />
  );
};

export default Chrome;
